var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"template-mapping-wizard__step template-mapping-wizard__wrapper"},[_c('div',{staticClass:"template-mapping-wizard__wrapper-content"},[_c('h2',{staticClass:"font-weight-regular mb-3 mt-6"},[_vm._v(" "+_vm._s(_vm.mappingStepTitle)+" ")]),_c('v-form',{ref:"form",staticClass:"mapping-step__add-field mt-10 d-flex justify-start",on:{"submit":function($event){$event.preventDefault();}}},[_c('div',[(!_vm.mappingDirection)?_c('div',{staticClass:"d-flex"},[_c('v-autocomplete',{staticClass:"mr-3",attrs:{"id":"mapping-wizard__workorder-combobox","name":"work-order-field","label":_vm.workOrderTemplateField.label,"items":_vm.workOrderFields,"loading":_vm.isLoading,"rules":[_vm.workOrderTemplateField.rules.required],"value":_vm.selectedWorkOrderField,"required":"","return-object":""},on:{"change":_vm.onChangeWorkOrderField,"keydown":_vm.onChangeWorkOrderField}}),(_vm.mappingType === 'template')?_c('v-autocomplete',{staticClass:"mr-3",attrs:{"id":"mapping-wizard__job-fields-combobox","name":"jobTemplateField","required":"","error-messages":_vm.errorMessages,"label":_vm.jobTemplateField.label,"items":_vm.jobFields,"loading":_vm.isLoading,"disabled":_vm.isLoading,"return-object":"","value":_vm.selectedJobField,"rules":[
              _vm.jobTemplateField.rules.required,
              _vm.jobTemplateField.rules.noDuplicates ]},on:{"change":_vm.onChangeJobField,"keydown":_vm.onChangeJobField}}):_c('v-text-field',{attrs:{"id":"mapping-wizard__label-text-input","required":"","error-messages":_vm.errorMessages,"rules":[
              _vm.inboundOutboundLabelField.rules.noDuplicates,
              _vm.inboundOutboundLabelField.rules.textLength ],"counter":"100","label":_vm.inboundOutboundLabelField.label},model:{value:(_vm.inboundOutboundLabelText),callback:function ($$v) {_vm.inboundOutboundLabelText=$$v},expression:"inboundOutboundLabelText"}})],1):_c('div',{staticClass:"d-flex"},[_c('v-autocomplete',{staticClass:"mr-3",attrs:{"id":"mapping-wizard__job-workflow-combox","name":"jobWorkflowName","required":"","label":_vm.jobWorkflowStep.label,"items":_vm.jobWorkflowSteps,"return-object":"","loading":_vm.loadingWorkflows,"value":_vm.selectedJobWorkflow,"rules":[
              _vm.jobWorkflowStep.rules.required ]},on:{"change":_vm.onChangeWorkflowField,"keydown":_vm.onChangeWorkflowField}}),_c('v-autocomplete',{staticClass:"mr-3",attrs:{"id":"mapping-wizard__workorder-returned-combox","name":"work-order-field","label":_vm.workOrderTemplateField.label,"items":_vm.workOrderFields,"loading":_vm.isLoading,"disabled":_vm.isLoading,"return-object":"","rules":[
              _vm.workOrderTemplateField.rules.required,
              _vm.workOrderTemplateField.rules.noDuplicates,
              _vm.workOrderTemplateField.rules.noMappingOverwrite ],"value":_vm.selectedWorkOrderField,"required":""},on:{"change":_vm.onChangeWorkOrderField,"keydown":_vm.onChangeWorkOrderField}})],1)]),(!_vm.mappingDirection)?_c('v-btn',{staticClass:"mt-3 ml-10",attrs:{"id":"mapping-wizard__add-mapping-btn","color":"primary","disabled":_vm.mappingType !== 'template' ? !_vm.validateInboundOutbound : !_vm.validateFields},on:{"click":_vm.onAddMapping}},[_vm._v(" Add ")]):_c('v-btn',{staticClass:"mt-3 ml-10",attrs:{"id":"mapping-wizard__add-workflow-mapping-btn","color":"primary","disabled":!_vm.validateWorkflowSteps},on:{"click":_vm.onAddWorkflowMapping}},[_vm._v(" Add ")])],1),_c('div',{staticClass:"mapping-step-table"},[_c('v-data-table',{key:_vm.renderKey,staticClass:"\n        mapping-step__data-table\n        fill-height\n        pt-5\n        d-flex\n        flex-column\n        justify-space-between\n      ",attrs:{"fixed-header":"","headers":_vm.mappingStepHeaders,"items":!_vm.mappingDirection ? _vm.mappings : _vm.workflowMappings,"hide-default-footer":"","disable-sort":"","disable-pagination":""},scopedSlots:_vm._u([{key:"header.actions",fn:function(ref){
            var header = ref.header;
return [_c('span',{staticClass:"d-sr-only"},[_vm._v(_vm._s(header.text))])]}},{key:"item.format",fn:function(ref){
            var item = ref.item;
return [_c('div',{},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-checkbox',_vm._g({attrs:{"id":"mapping-wizard__format-value-checkbox","true-value":"string","false-value":"number","color":"primary"},on:{"change":function($event){return _vm.updateMapping()}},model:{value:(item.format),callback:function ($$v) {_vm.$set(item, "format", $$v)},expression:"item.format"}},on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.format === 'string' ? 'String Format' : 'Database Format'))])])],1)]}},{key:"header.description",fn:function(){return [_vm._v(" Use Description? "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var attrs = ref.attrs;
            var on = ref.on;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v("Submit the selected option's description instead of the code")])])]},proxy:true},{key:"item.description",fn:function(ref){
            var item = ref.item;
return [_c('v-checkbox',{attrs:{"color":"primary","true-value":"description","false-value":"code"},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}})]}},{key:"item.emptyValue",fn:function(ref){
            var item = ref.item;
return [_c('div',{},[_c('v-autocomplete',{staticClass:"mapping-wizard__combo-box",attrs:{"id":"mapping-wizard__empty-value-combox","items":_vm.emptyValueOptions},on:{"change":function($event){return _vm.updateMapping()}},model:{value:(item.emptyValue),callback:function ($$v) {_vm.$set(item, "emptyValue", $$v)},expression:"item.emptyValue"}})],1)]}},{key:"item.inboundOverwrite",fn:function(ref){
            var item = ref.item;
return [_c('v-simple-checkbox',{staticClass:"align-center justify-center",attrs:{"id":"mapping-wizard__inbound-overwrite-checkbox","ripple":false,"color":"primary"},model:{value:(item.inboundOverwrite),callback:function ($$v) {_vm.$set(item, "inboundOverwrite", $$v)},expression:"item.inboundOverwrite"}})]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-space-around justify-lg-start"},[(_vm.mappingType === 'inbound')?_c('MappingWizardInboundLabelEditDialog',{attrs:{"inbound-label":item.inboundLabel}}):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"id":"mapping-wizard__delete-mapping-btn","icon":""},on:{"click":function($event){return _vm.onDeleteMapping(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1)]}}],null,true)})],1)],1),_c('v-divider',{staticClass:"mb-3"}),_c('div',{staticClass:"template-mapping-wizard__wrapper-navigation d-flex justify-end"},[(((!_vm.editMapping) || (_vm.editMapping && _vm.mappingDirection)))?_c('v-btn',{staticClass:"template-mapping-wizard__back-btn align-self-end mr-4",attrs:{"id":"mapping-wizard__back-btn","color":"secondary"},on:{"click":_vm.navigateBack}},[_vm._v(" Back ")]):_vm._e(),_c('v-btn',{staticClass:"template-mapping-wizard__next-btn align-self-end",attrs:{"id":"mapping-wizard__next-btn","color":"primary","disabled":_vm.disableButton},on:{"click":_vm.navigateNext}},[_vm._v(" Next ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }