var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"template-mapping-wizard__step template-mapping-wizard__wrapper"},[_c('div',{staticClass:"template-mapping-wizard__wrapper-content"},[_c('h1',{staticClass:"font-weight-regular"},[_vm._v(" Almost there! ")]),(!_vm.editMapping)?_c('p',{staticClass:"mb-10"},[_vm._v(" Your mapping is almost ready. Review the details below and hit "),_c('b',[_vm._v("Create")]),_vm._v(" if happy, or "),_c('b',[_vm._v("Back")]),_vm._v(" if you wish to make changes ")]):_c('p',{staticClass:"mb-5"},[_vm._v(" Please Review the details below and hit "),_c('b',[_vm._v("Save")]),_vm._v(" if happy, or "),_c('b',[_vm._v("Back")]),_vm._v(" if you wish to make changes ")]),(_vm.mappingType === 'outbound')?_c('div',{staticClass:"mb-1 d-flex align-center "},[_c('h2',{staticClass:"font-weight-regular text-sm-h6 pr-4"},[_vm._v(" Endpoint Address: ")]),_c('p',{staticClass:"mb-0 font-weight-light"},[_vm._v(" "+_vm._s(_vm.endpointAddress)+" ")])]):_vm._e(),(_vm.mappingType === 'inbound')?_c('h5',{staticClass:"font-weight-regular text-sm-h6 mb-0 pb-0"},[_vm._v(" Mapping Details: ")]):_vm._e(),(_vm.mappingType === 'inbound')?_c('v-data-table',{staticClass:"\n        summary-step__data-table\n        fill-height\n        mt-2\n        flex-column\n        justify-space-between\n      ",attrs:{"fixed-header":"","headers":_vm.headers,"items":_vm.mappings,"hide-default-footer":"","disable-sort":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.inboundOverwrite",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{staticClass:"align-center justify-center",attrs:{"ripple":false,"value":item.inboundOverwrite,"readonly":""}})]}}],null,true)}):_c('v-tabs',{staticClass:"pt-6",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{attrs:{"id":"mapping-summary-wizard__tab-1"}},[_vm._v(" "+_vm._s(_vm.mappingType === 'template' ? 'Job to Work Order' : 'Outbound Mappings')+" ")]),_c('v-tab',{attrs:{"id":"mapping-summary-wizard__tab-2"}},[_vm._v(" "+_vm._s(_vm.mappingType === 'template' ? 'Work Order to Job' : 'Alarms')+" ")])],1),_c('v-tabs-items',{attrs:{"touchless":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-data-table',{staticClass:"\n        summary-step__data-table\n        fill-height\n        mt-2\n        flex-column\n        justify-space-between\n      ",attrs:{"fixed-header":"","headers":_vm.headers,"items":_vm.mappings,"hide-default-footer":"","disable-sort":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.format",fn:function(ref){
var item = ref.item;
return [_c('div',{},[_vm._v(" "+_vm._s(item.format === 'string' ? 'Y' : 'N')+" ")])]}},{key:"item.emptyValue",fn:function(ref){
var item = ref.item;
return [_c('div',{},[_vm._v(" "+_vm._s(item.emptyValue === 'null' ? 'null' : "' '")+" ")])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"color":"primary","readonly":"","disabled":true,"true-value":"description","false-value":"code"},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}})]}}],null,true)})],1),_c('v-tab-item',[_c('v-data-table',{staticClass:"\n        summary-step__data-table\n        fill-height\n        mt-2\n        flex-column\n        justify-space-between\n      ",attrs:{"fixed-header":"","headers":_vm.mappingType === 'template' ? _vm.workflowHeaders : _vm.alarmHeaders,"items":_vm.mappingType === 'template' ? _vm.workflowMappings : _vm.alarms,"hide-default-footer":"","disable-sort":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"color":"primary","readonly":"","disabled":true,"true-value":"description","false-value":"code"},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}})]}}],null,true)})],1)],1)],1),_c('v-divider',{staticClass:"mb-3"}),_c('div',{staticClass:"template-mapping-wizard__wrapper-navigation d-flex justify-end"},[_c('v-btn',{staticClass:"template-mapping-wizard__back-btn align-self-end mr-4",attrs:{"id":"mapping-summary-wizard__back-btn","color":"secondary","disabled":_vm.loading || _vm.disableSaveButton},on:{"click":_vm.navigateBack}},[_vm._v(" Back ")]),_c('v-btn',{staticClass:"template-mapping-wizard__create-btn align-self-end",attrs:{"id":"mapping-summary-wizard__save-btn","color":"primary","loading":_vm.loading,"disabled":_vm.disableSaveButton},on:{"click":_vm.createNewMapping}},[_vm._v(" "+_vm._s(_vm.editMapping ? 'Save' : 'Create')+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }