




























































































































































import {
  defineComponent,
  PropType,
  Ref,
  ref,
  watch,
  computed,
} from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';
import router from '@/router';
import Store from '@/store';
import TemplateCreateWizardCloseDialog from '@/components/TemplateCreateWizard/TemplateCreateWizardCloseDialog.vue';
import MappingWizardWorkOrderTemplateStep from '@/components/MappingWizardComponents/MappingWizardWorkOrderTemplateStep.vue';
import TemplateMappingWizardJobTemplateStep from '@/components/MappingWizardComponents/TemplateMappingWizardJobTemplateStep.vue';
import OutboundMappingWizardNameStep from '@/components/OutboundMappingWizard/OutboundMappingWizardNameStep.vue';
import MappingWizardMappingStep from '@/components/MappingWizardComponents/MappingWizardMappingStep.vue';
import OutboundMappingWizardAlarmsStep from '@/components/OutboundMappingWizard/OutboundMappingWizardAlarmsStep.vue';
import OutboundMappingWizardEndpointStep from '@/components/OutboundMappingWizard/OutboundMappingWizardEndpointStep.vue';
import MappingWizardSummaryStep from '@/components/MappingWizardComponents/MappingWizardSummaryStep.vue';

export default defineComponent({
  components: {
    TemplateCreateWizardCloseDialog,
    MappingWizardWorkOrderTemplateStep,
    TemplateMappingWizardJobTemplateStep,
    OutboundMappingWizardNameStep,
    MappingWizardMappingStep,
    OutboundMappingWizardAlarmsStep,
    OutboundMappingWizardEndpointStep,
    MappingWizardSummaryStep,
  },
  props: {
    mappingType: {
      type: String as PropType<'template' | 'outbound' | 'inbound'>,
      default: '',
    },
    mappingStepTitle: {
      type: String,
      default: '',
    },
    mappingStepSecondTitle: {
      type: String,
      default: '',
    },
    mappingStepHeaders: {
      type: Array as PropType<DataTableHeader[]>,
      default: () => [],
    },
    mappingStepSecondHeaders: {
      type: Array as PropType<DataTableHeader[]>,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    startStep: {
      type: Number,
      default: 1,
    },
    editMapping: {
      type: Boolean,
      default: false,
    },
    loadFields: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: 'New',
    },
    // The current job of the WOTemplate. Used for template mappings.
    currentJob: {
      type: Number,
      default: null,
    },
  },
  emits: ['closeDialog', 'reloadOutboundData'],
  setup(props, { emit }) {
    const outboundWizardAlarmsStep = ref();
    const mappingStep = ref();
    const valid = false;

    const dialog = ref(false);

    const form = ref();

    const mappingNamespace = `${props.mappingType}Mapping`;

    const isLoading = computed(() => Store.getters['templateMapping/getLoading']);
    const mappingsLoading = computed(() => Store.getters['templateMapping/getMappingsLoading']);
    const outboundLoading = computed(() => Store.getters['outboundMapping/getOutboundMessageLoading']);

    const loadFieldData = async () => {
      if (props.loadFields) {
        const { templateId } = router.app.$route.params;
        Store.dispatch('templateMapping/setWorkOrderTemplate', templateId);
      }
      // use already set up vuex actions
      await Store.dispatch('templateMapping/loadWorkOrderFields');

      if (props.mappingType === 'template') await Store.dispatch('templateMapping/loadJobFields');
    };

    /**
     * Step must be a number (or a number in a string) otherwise stepper
     * component will not make a back animation.
     *
     * Steps:
     * 1 - Work Order Template
     * 2 - Template Status or Job Template
     */
    const step: Ref<string | number> = ref(''); // init step

    const openDialog = () => {
      step.value = props.startStep;

      if (mappingStep.value) mappingStep.value.loadWorkOrderFields();

      if (outboundWizardAlarmsStep.value) outboundWizardAlarmsStep.value.resetSelectedTrigger();

      if (!props.editMapping && props.mappingType !== 'template') {
        Store.dispatch(`${mappingNamespace}/setMappings`, []);
        if (props.mappingType === 'outbound') {
          Store.dispatch(`${mappingNamespace}/setAlerts`, []);
          Store.dispatch(`${mappingNamespace}/setEndpoint`, '');
        }
      }
    };

    /**
     *  @summary Method closes the wizard dialog and depending on the props (edit mapping and mapping type) it clears certain data.
     *  @description First check in this method is to see if the mapping type is an Edit Mapping or New Mapping.
     *    If it an 'edit' the 'removeUnsavedMappings' function will be called to remove any unsaved mappings that may have been added before the process was canceled.
     *    Otherwise it 'new mapping' there is then a serious of check on the mappingType.
     *    If it is a 'Template Mapping' then the function 'cancelAllCurrentMappings' is called to clear all added mappings that have been canceled.
     *    other wise it is an 'Outbound Mapping' or an 'Inbound Mapping' for both processes 'setMappings' & 'setMappedTemplates' is called to clear mapping data and to reload
     *    mapped templates.
     *    For 'Outbound Mapping' an additional call is made to clear the endpoint and to clear the alerts.
     *  @author EJ McVey
     */
    const closeDialog = () => {
      if (!props.editMapping) {
        if (props.mappingType !== 'template') {
          if (props.mappingType === 'outbound') {
            Store.dispatch(`${mappingNamespace}/setAlerts`, []);
            Store.dispatch(`${mappingNamespace}/setEndpoint`, '');
            emit('reloadOutboundData');
          }
          // inbound & outbound
          Store.dispatch(`${mappingNamespace}/setMappings`, []);
          Store.dispatch(`${mappingNamespace}/setMappedTemplates`);
        } else {
          // template
          Store.dispatch('templateMapping/cancelAllCurrentMappings');
          Store.dispatch(`${mappingNamespace}/loadTemplateMappings`);
        }
        // user is editing - call reload data for outbound
      } else {
        Store.dispatch('templateMapping/removeUnsavedMappings');
        emit('reloadOutboundData');
      }

      dialog.value = false;
    };

    /**
     * Changes current step
     * @param {string} newStep New step to be displayed
     */
    const changeStep = (newStep: string | number) => {
      step.value = newStep;
    };

    // Watch if dialog has been closed, if true clears form; validation and store
    watch(dialog, (from, to) => {
      if (!from && to && form.value) {
        form.value.reset();
        form.value.resetValidation();
        changeStep(1);
      }
    });

    /**
     * On navigateNext event emitted we determine the next stepper step
     * based on the current step.
     * @param {string} fromStep Step that's triggering the event
     */
    const onNavigateNext = (fromStep: string) => {
      switch (fromStep) {
        case 'workOrderTemplate':
          if (props.mappingType === 'inbound') {
            loadFieldData();
            changeStep(3);
          } else changeStep(2);
          break;
        case 'status':
          loadFieldData();
          changeStep(3);
          break;
        case 'jobTemplate':
          loadFieldData();
          changeStep(3);
          break;
        case 'mappingList':
          if (props.mappingType === 'inbound') {
            changeStep(5);
          } else changeStep(4);
          break;
        case 'mappingWorkflowList':
          changeStep(5);
          break;
        case 'alarms':
          changeStep(5);
          break;
        case 'endpoint':
          changeStep(6);
          break;
        default:
          changeStep(1);
          break;
      }
    };

    /**
     * On onNavigateBack event emitted we determine the previous stepper step
     * based on the current step.
     * @param {string} fromStep Step that's triggering the event
     */
    const onNavigateBack = (fromStep: string) => {
      switch (fromStep) {
        case 'status':
          changeStep(1);
          break;
        case 'jobTemplate':
          changeStep(1);
          break;
        case 'mappingList':
          changeStep(2);
          break;
        case 'mappingWorkflowList':
          changeStep(3);
          break;
        case 'mappingSummary':
          changeStep(props.mappingType === 'outbound' ? 5 : 4);
          if (props.mappingType === 'template') changeStep(4);
          else if (props.mappingType === 'outbound') changeStep(5);
          else changeStep(3);
          break;
        case 'alarms':
          changeStep(3);
          break;
        case 'endpoint':
          changeStep(4);
          break;
        default:
          changeStep(1);
          break;
      }
    };

    return {
      valid,
      dialog,
      openDialog,
      closeDialog,
      step,
      form,
      isLoading,
      onNavigateNext,
      onNavigateBack,
      mappingStep,
      mappingsLoading,
      outboundWizardAlarmsStep,
      outboundLoading,
    };
  },
});
