




















































































































































































import {
  computed, ref, defineComponent, PropType,
} from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';
import Store from '@/store';
import router from '@/router';
import { IOutboundAlert } from '@/types';

export default defineComponent({
  props: {
    mappingType: {
      type: String as PropType<'template' | 'outbound' | 'inbound'>,
      default: '',
    },
    mappingStepHeaders: {
      type: Array as PropType<DataTableHeader[]>,
      default: () => [],
    },
    editMapping: {
      type: Boolean,
      default: false,
    },
    mappingStepSecondHeaders: {
      type: Array as PropType<DataTableHeader[]>,
      default: () => [],
    },
    currentJob: {
      type: Number,
      default: null,
    },
  },
  emit: ['navigateBack'],
  setup(props, { emit }) {
    const tab = null;

    const disableSaveButton = ref(false);

    const mappingNamespace = `${props.mappingType}Mapping`;

    const loading = computed(() => Store.getters[`${mappingNamespace}/getLoading`]);

    const currentJob = props.currentJob ? props.currentJob : Store.getters['templateMapping/getJobTemplateRefId'];

    const headers = props.mappingStepHeaders.filter((header) => header.value !== 'actions');
    const workflowHeaders = props.mappingStepSecondHeaders.filter((header) => header.value !== 'actions');

    const templateId = router.app.$route.params.templateId
      ? router.app.$route.params.templateId : Store.getters['templateMapping/getWorkOrderTemplateId'];

    const endpointAddress = computed(() => Store.getters['outboundMapping/getEndpoint']);

    // OUTBOUND Alarm summary info
    const alarmHeaders = [
      { text: 'When', align: 'start', value: 'action' },
      { text: 'What', value: 'customFieldName' },
      { text: 'Condition', value: 'criteriaText' },
      { text: 'Value', value: 'value' },
    ];

    const alarms = computed(
      (): (IOutboundAlert)[] => Store.getters['outboundMapping/getAlerts'],
    );

    // TEMPLATE Mappings Summary info
    const mappings = computed(() => {
      let returnable = Store.getters[`${mappingNamespace}/getMappings`];
      if (props.mappingType === 'template' && Object.keys(returnable).length > 0) {
        returnable = returnable[templateId][currentJob];

        const deleted = Store.getters['templateMapping/getDeletedMappings'].filter((i: any) => i.type === 'field');
        for (let index = 0; index < deleted.length; index++) {
          returnable = returnable.filter((i: any) => i.id !== deleted[index].id);
        }
      }
      return returnable;
    });

    const workflowMappings = computed(
      () => {
        let returnable = Store.getters['templateMapping/getWorkflowMappings'];
        if (props.mappingType === 'template') {
          returnable = returnable[templateId][currentJob];

          const deleted = Store.getters['templateMapping/getDeletedMappings'].filter((i: any) => i.type === 'workflow');
          for (let index = 0; index < deleted.length; index++) {
            returnable = returnable.filter((i: any) => i.id !== deleted[index].id);
          }
        }

        return returnable;
      },
    );

    /**
       * Emits an event warning that user wants to navigate to the previous page
       * of the wizard and passes the current step as param
       */
    const navigateBack = () => {
      emit('navigateBack', 'mappingSummary');
    };

    /**
       * Create New Mapping FN
       */
    const createNewMapping = async () => {
      disableSaveButton.value = true;

      if (props.mappingType === 'inbound') {
        await Store.dispatch(`${mappingNamespace}/saveMappingConfigurations`, props.editMapping);
      } else if (props.mappingType === 'outbound') {
        const actionCall = props.editMapping ? 'updateOutboundMessage' : 'createOutboundMessage';

        await Store.dispatch(`${mappingNamespace}/${actionCall}`);
      } else if (props.mappingType === 'template') {
        // declare empty arrays to assign custom objects to
        const fields = [];
        const workflows = [];

        for (let index = 0; index < mappings.value.length; index++) {
          fields.push({
            id: mappings.value[index].id,
            customFieldId: mappings.value[index].workOrderFieldId,
            fieldId: mappings.value[index].jobFieldId,
            options: {
              use: mappings.value[index].description,
            },
          });
        }

        for (let index = 0; index < workflowMappings.value.length; index++) {
          workflows.push({
            id: workflowMappings.value[index].id,
            customFieldId: workflowMappings.value[index].workOrderFieldId,
            workflow_id: workflowMappings.value[index].jobWorkflowId,
            options: {
              use: workflowMappings.value[index].description,
            },
          });
        }

        await Store.dispatch('templateMapping/createFieldMappings', fields);
        await Store.dispatch('templateMapping/createWorkflowMappings', workflows);
      }

      emit('closeWizard');
      disableSaveButton.value = false;
    };

    return {
      tab,
      headers,
      mappings,
      loading,
      alarms,
      alarmHeaders,
      navigateBack,
      createNewMapping,
      workflowHeaders,
      workflowMappings,
      endpointAddress,
      disableSaveButton,
    };
  },
});
