



















import { computed, defineComponent } from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';
import TemplateMappingsTable from '@/components/MappingsTableComponent.vue';
import MappingWizardDialog from '@/components/MappingWizardComponents/MappingWizardDialog.vue';
import Store from '@/store';
import router from '@/router';
import { IWorkorderTemplateData } from '@/types';

export default defineComponent({
  components: {
    TemplateMappingsTable,
    MappingWizardDialog,
  },
  setup() {
    const templateMappings = computed(() => Store.getters['templateMapping/getMappedTemplates']);
    if (!templateMappings.value || (templateMappings.value && templateMappings.value.length < 1)) {
      // if the getter doesn't have data, load from the API instead.
      Store.commit('templateMapping/setLoading', true);
      Store.dispatch('templateMapping/loadTemplateMappings').then(() => {
        Store.commit('templateMapping/setLoading', false);
      });
    }

    const mappingStepTitle = 'Let\'s map the Work Order information that will be populated into the Job upon creation.';
    const mappingStepSecondTitle = 'Now let\'s map the Job data to be returned to your Work Order.';

    const mappingStepHeaders: DataTableHeader[] = [
      {
        text: 'Work Order Field',
        align: 'start',
        value: 'workOrderFieldName',
      },
      { text: 'Job Field', value: 'jobFieldName' },
      { text: 'Description', value: 'description' },
      { text: 'Actions', value: 'actions', sortable: false },
    ];

    const mappingStepSecondHeaders: DataTableHeader[] = [
      {
        text: 'Job Workflow Step',
        align: 'start',
        value: 'jobWorkflowName',
      },
      { text: 'Work Order Field', value: 'workOrderFieldName' },
      { text: 'Description', value: 'description' },
      { text: 'Actions', value: 'actions', sortable: false },
    ];

    const openEditPage = (value: IWorkorderTemplateData) => {
      router.push({
        name: 'TemplateMappingsCustomisation',
        params: {
          templateId: value.id,
          isPublished: value.published.toString(),
        },
      });
    };

    return {
      openEditPage,
      mappingStepTitle,
      mappingStepHeaders,
      mappingStepSecondHeaders,
      mappingStepSecondTitle,
      templateMappings,
    };
  },
});
