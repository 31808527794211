






































import {
  defineComponent,
  ref,
  computed,
  onMounted,
  PropType,
} from '@vue/composition-api';
import Store from '@/store';
import { IVComboboxItem } from '@/types';

export default defineComponent({
  props: {
    mappingType: {
      type: String as PropType<'template' | 'outbound' | 'inbound'>,
      default: '',
    },
  },
  emit: ['navigateNext'],
  setup(props, { emit }) {
    onMounted(() => {
      Store.dispatch('templateList/getUnpublishedTemplatesData');
      Store.dispatch('templateList/filter');
    });

    const mappingNamespace = `${props.mappingType}Mapping`;

    const selectedTemplate = ref();

    const isLoading = computed(
      () => Store.getters['templateList/getLoading'] as boolean,
    );

    const isLoadingUnpublishedTemplates = computed(
      () => Store.getters['templateList/getLoadingUnpublishedTemplates'] as boolean,
    );

    const errorMessages = ref('');

    const templateNameField = {
      label: 'Template Name',
      rules: {
        /**
         * Checks if value is not falsy
         * @param {string} value Value to be checked
         * @return {boolean | string} True if valid or a text if false
         */
        required: (value: string): boolean | string => !!value || 'This field is required',
      },
      items: (): IVComboboxItem[] => Store.getters['templateList/getUnpublishedTemplatesData'] as IVComboboxItem[],
    };

    /**
     * Checks if template name field is valid
     * @return {boolean} True if valid
     */
    const validateTemplateName = computed((): boolean => {
      const workOrderTemplateValue = Store.getters[`${mappingNamespace}/getData`] ? Store.getters[`${mappingNamespace}/getData`].workOrderTemplate : '';

      if (!workOrderTemplateValue) return false;

      const required = templateNameField.rules.required(workOrderTemplateValue);
      return !isLoading.value && typeof required === 'boolean' && required;
    });

    /**
     * On template name field input update work order template details in store
     * @param {ITemplateSearchRecord} value Field value
     */
    const onInputName = (workOrderTemplate: IVComboboxItem) => {
      const value = workOrderTemplate && workOrderTemplate.value
        ? workOrderTemplate.value
        : '';

      // set ID for loading fields in general store
      Store.dispatch('templateMapping/setWorkOrderTemplate', value);
      // set id for specific store for api calls
      Store.dispatch(`${mappingNamespace}/setWorkOrderTemplate`, value);

      if (props.mappingType === 'outbound') {
        Store.dispatch('templateStatus/setTemplateId', value);
        Store.dispatch('templateStatus/loadData');
      } else if (props.mappingType === 'inbound') {
        Store.dispatch('inboundMapping/loadTemplateData');
      }
    };

    /**
     * Emits an event warning that user wants to navigate to the next page
     * of the wizard and passes the current step as param
     */
    const navigateNext = () => {
      Store.dispatch('templateMapping/setWorkOrderTemplate', selectedTemplate.value.value);
      emit('navigateNext', 'workOrderTemplate');
    };

    return {
      selectedTemplate,
      templateNameField,
      validateTemplateName,
      errorMessages,
      isLoading,
      isLoadingUnpublishedTemplates,
      onInputName,
      navigateNext,
    };
  },
});
