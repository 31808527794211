

















































































































































































































































































import {
  defineComponent,
  ref,
  Ref,
  computed,
  PropType,
} from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';
import Store from '@/store';
import router from '@/router';
import {
  ITemplateSectionField,
  ITemplateMappingItem,
  IOutboundMappingItem,
  IInboundMappingItem,
  IVComboboxItem,
  ITemplateWorkflowMappingItem,
} from '@/types';
import MappingWizardInboundLabelEditDialog from './MappingWizardInboundLabelEditDialog.vue';

export default defineComponent({
  emit: ['navigateNext', 'navigateBack'],
  components: { MappingWizardInboundLabelEditDialog },
  props: {
    mappingType: {
      type: String as PropType<'template' | 'outbound' | 'inbound'>,
      default: '',
    },
    mappingStepTitle: {
      type: String,
      default: '',
    },
    mappingStep: {
      type: Number,
      default: 0,
    },
    mappingStepSecondTitle: {
      type: String,
      default: '',
    },
    mappingStepHeaders: {
      type: Array as PropType<DataTableHeader[]>,
      default: () => [],
    },
    editMapping: {
      type: Boolean,
      default: false,
    },
    mappingDirection: {
      type: String,
      default: '',
    },
    currentJob: {
      type: Number,
      default: null,
    },
  },
  setup(props, { emit }) {
    const form = ref();
    const selectedJobField = ref();
    const selectedJobWorkflow = ref();
    const selectedWorkOrderField = ref();
    const errorMessages = ref('');
    const renderKey = ref(0);
    // const wfRenderKey = ref(0);

    const templateId = router.app.$route.params.templateId
      ? router.app.$route.params.templateId : Store.getters['templateMapping/getWorkOrderTemplateId'];

    // Defines the namespace for store calls based on the prop mappingType
    const mappingNamespace = `${props.mappingType}Mapping`;

    // empty value type combo
    const emptyValueOptions = ['string', 'null'];

    // Outbound Label
    const inboundOutboundLabelText = ref('');

    const isLoading = computed(
      () => Store.getters['templateMapping/getLoading'] as boolean,
    );
    const loadingWorkflows = ref(false);

    const currentJob = props.currentJob ? props.currentJob : Store.getters['templateMapping/getJobTemplateRefId'];

    // template ID gathered from the URL, currentJob parsed as a prop. Retrieves data from state.
    const mappings = computed(() => {
      let returnable = Store.getters[`${mappingNamespace}/getMappings`];
      if (props.mappingType === 'template' && Object.keys(returnable).length > 0) {
        if (returnable[templateId] && returnable[templateId][currentJob]) returnable = returnable[templateId][currentJob];

        const deleted = Store.getters['templateMapping/getDeletedMappings'].filter((i: any) => i.type === 'field');
        for (let index = 0; index < deleted.length; index++) {
          returnable = returnable.filter((i: any) => i.id !== deleted[index].id);
        }
      }
      return returnable;
    });

    const outboundMessage = computed(() => Store.getters[`${mappingNamespace}/getEditedOutboundMessage`]);
    const mappingBackDirection = !props.mappingDirection ? 'mappingList' : 'mappingWorkflowList';

    const mappedFields = computed(() => Store.getters['templateMapping/getMappedFields']);

    // Second Mapping screen for Job Workflow steps -> WO Template
    const workflowMappings = computed(
      ():ITemplateWorkflowMappingItem[] => {
        let returnable = Store.getters['templateMapping/getWorkflowMappings'];
        if (props.mappingType === 'template' && Object.keys(returnable).length > 0) {
          if (returnable[templateId] && returnable[templateId][currentJob]) returnable = returnable[templateId][currentJob];

          const deleted = Store.getters['templateMapping/getDeletedMappings'].filter((i: any) => i.type === 'workflow');
          for (let index = 0; index < deleted.length; index++) {
            returnable = returnable.filter((i: any) => i.id !== deleted[index].id);
          }
        }
        return returnable;
      },
    );

    const isMappingAdded = computed(() => {
      const currentMappings = !props.mappingDirection ? mappings : workflowMappings;
      return currentMappings.value && currentMappings.value.length > 0;
    });

    const jobFields = computed(() => {
      const items = Store.getters['templateMapping/getJobFields'];
      if (!items) return [];
      const newObj = items.map((item: any) => ({
        text: item.name ? item.name : item.text,
        value: item.id ? item.id : item.value,
      }));

      return newObj;
    });

    const jobWorkflowSteps = computed(() => Store.getters['templateMapping/getJobWorkflows']);

    // If editing mappings - load WO fields. (only inbound atm)
    const loadWorkOrderFields = async () => {
      await Store.dispatch('templateMapping/setWorkOrderTemplate', templateId);
      await Store.dispatch('templateMapping/loadWorkOrderFields');

      if (props.mappingType === 'template') {
        Store.dispatch('templateMapping/loadJobFields');
      }
    };

    if (props.mappingDirection) {
      loadingWorkflows.value = true;
      Store.dispatch('templateMapping/loadJobWorkflows');
      Store.dispatch('templateMapping/loadAllJobMappings', templateId);
      loadingWorkflows.value = false;
    }
    // load status's for outbound alarms
    if (props.mappingType === 'outbound') {
      Store.dispatch('templateStatus/setTemplateId', templateId);
      Store.dispatch('templateStatus/loadData');
    }
    if (props.editMapping) {
      // load wo template fields & job template fields to populate dropdown
      loadWorkOrderFields();
    }

    const workOrderFields = computed(
      () => {
        const allFields = Store.getters['templateMapping/getWorkOrderFields'] as ITemplateSectionField;

        // remove table & job list fields not applicable to mapping
        const ignoreFields = ['table', 'joblist'];
        return allFields.filter((field: ITemplateSectionField) => !ignoreFields.includes(field.type));
      },
    );

    const jobTemplateField = {
      label: 'Job Template Field',
      rules: {
        noDuplicates: (value: IVComboboxItem) => {
          if (value) {
            const fieldId = value.value;
            const itemIndex = mappings.value.findIndex((item: ITemplateMappingItem) => 'jobFieldId' in item && item.jobFieldId === fieldId);
            return itemIndex >= 0 ? 'This field is already mapped' : true;
          }
          return true;
        },

        /**
         * Checks if value is not falsy
         * Don't show error message when 'null' as that's the field value when it's
         * cleared after adding a new mapping
         * @param {string} value Value to be checked
         * @return {boolean | string} True if valid or a text if false
         */
        required: (field: string | number | undefined): boolean | string => {
          if (field == null) return true;
          if (!field) return 'This field is required';
          return true;
        },
      },
    };

    const jobWorkflowStep = {
      label: 'Job Workflow Step',
      rules: {
        noDuplicates: (value: IVComboboxItem) => {
          if (value) {
            const workflowId = value.value;
            const itemIndex = workflowMappings.value.findIndex((item) => 'jobWorkflowId' in item && item.jobWorkflowId === workflowId);
            return itemIndex >= 0 ? 'This field is already mapped' : true;
          }
          return true;
        },

        /**
         * Checks if value is not falsy
         * Don't show error message when 'null' as that's the field value when it's
         * cleared after adding a new mapping
         * @param {string} value Value to be checked
         * @return {boolean | string} True if valid or a text if false
         */
        required: (workflow: string | number | undefined): boolean | string => {
          if (workflow == null) return true;
          if (!workflow) return 'This workflow is required';
          return true;
        },
      },
    };

    const workOrderTemplateField = {
      label: 'Work Order Template Field',
      rules: {
        noDuplicates: (value: IVComboboxItem) => {
          if (value) {
            const workOrderFieldId = value.value;
            const itemIndex = workflowMappings.value.findIndex((item) => 'workOrderFieldId' in item && item.workOrderFieldId === workOrderFieldId);
            return itemIndex >= 0 ? 'This field is already mapped' : true;
          }
          return true;
        },

        /**
           * Checks if value is not falsy
           * Don't show error message when 'null' as that's the field value when it's cleared
           * after adding a new mapping
           * @param {string} value Value to be checked
           * @return {boolean | string} True if valid or a text if false
           */
        required: (field: string | number | undefined): boolean | string => {
          if (field == null) return true;
          if (!field) return 'This field is required';
          return true;
        },

        /**
         * Checks if the selected work order field isn't already mapped with another job's workflow
         */
        noMappingOverwrite: (value: IVComboboxItem) => {
          if (value) {
            const workOrderFieldId = value.value;
            const isMapped = mappedFields.value.includes(workOrderFieldId);
            return !isMapped || 'This field is already mapped with another job template';
          }
          return true;
        },
      },
    };

    const inboundOutboundLabelField = {
      label: props.mappingType === 'outbound' ? 'Outbound Label' : 'Inbound Label',
      rules: {
        noDuplicates: (value: string) => {
          let itemIndex;

          if (props.mappingType === 'outbound') {
            itemIndex = mappings.value.findIndex((item: IOutboundMappingItem) => 'label' in item && item.label === value);
          } else {
            itemIndex = mappings.value.findIndex((item: IInboundMappingItem) => 'inboundLabel' in item && item.inboundLabel === value);
          }

          return itemIndex >= 0 ? 'Label has already been used' : true;
        },
        textLength: (v: string): boolean | string => {
          if (!v) return true;
          if (v.length < 3) return 'Min 3 characters';
          return v.length <= 100 || 'Max 100 characters';
        },
      },
    };

    /**
       * Check if a value has been selected set it
       */
    const onChangeJobField = (jobField: Ref<IVComboboxItem> | undefined) => {
      selectedJobField.value = jobField && jobField.value ? jobField : '';
    };

    /**
       * Check if a value has been selected set it
       */
    const onChangeWorkflowField = (jobWorkflow: Ref<IVComboboxItem> | undefined) => {
      selectedJobWorkflow.value = jobWorkflow && jobWorkflow.value ? jobWorkflow : '';
    };

    /**
       * Check if a value has been selected set it
       */
    const onChangeWorkOrderField = (
      workOrderField: Ref<IVComboboxItem> | undefined,
    ) => {
      selectedWorkOrderField.value = workOrderField && workOrderField.value ? workOrderField : '';
    };

    /**
     * Set the input field error message prop
     */
    const setErrorMessages = (value: string) => {
      errorMessages.value = value;
    };

    /**
       * Check if the 2 combobox fields pass validation i.e they both have values
       * and the user isn't trying to map multiple work order fields to the same job field.
       */
    const validateFields = computed((): boolean => {
      if (!selectedJobField.value || !selectedWorkOrderField.value) return false;

      const required = jobTemplateField.rules.required(selectedJobField.value.value)
          && workOrderTemplateField.rules.required(
            selectedWorkOrderField.value.value,
          );
      const noDuplicates = jobTemplateField.rules.noDuplicates(
        selectedJobField.value,
      );

      if (typeof noDuplicates === 'string') setErrorMessages(noDuplicates);
      else setErrorMessages('');

      return (
        typeof required === 'boolean'
          && required
          && typeof noDuplicates === 'boolean'
          && noDuplicates
      );
    });

    const validateWorkflowSteps = computed((): boolean => {
      if (!selectedJobWorkflow.value || !selectedWorkOrderField.value) return false;

      const noDuplicates = workOrderTemplateField.rules.noDuplicates(
        selectedWorkOrderField.value,
      );

      const noMappingOverwrite = workOrderTemplateField.rules.noMappingOverwrite(
        selectedWorkOrderField.value,
      );

      if (typeof noDuplicates === 'string') setErrorMessages(noDuplicates);
      else setErrorMessages('');

      return (
        typeof noDuplicates === 'boolean'
          && noDuplicates
        && typeof noMappingOverwrite === 'boolean'
          && noMappingOverwrite
      );
    });

    /**
       * Outbound Form Validation
       * Check for duplication of labels & 2 field values exist
       */
    const validateInboundOutbound = computed((): boolean => {
      const noDuplicates = inboundOutboundLabelField.rules.noDuplicates(
        inboundOutboundLabelText.value,
      );
      if (typeof noDuplicates === 'string' || !inboundOutboundLabelText.value) return false;

      const fieldLength = inboundOutboundLabelField.rules.textLength(
        inboundOutboundLabelText.value,
      );

      return (
        inboundOutboundLabelText.value
        && selectedWorkOrderField.value
        && typeof fieldLength === 'boolean'
          && fieldLength
      );
    });

    /**
       * Add the mapping between the selected fields into vuex and clear the field values
       */
    const onAddMapping = () => {
      const mappingObject: ITemplateMappingItem | IInboundMappingItem = {
        workOrderFieldId: selectedWorkOrderField.value.value,
        workOrderFieldName: selectedWorkOrderField.value.text,
      };

      if (props.mappingType === 'template') {
        Object.assign(mappingObject, {
          jobFieldId: selectedJobField.value.value,
          jobFieldName: selectedJobField.value.text,
        });
        selectedJobField.value = null;
        Store.commit('templateMapping/addMapping', {
          templateId,
          jobId: currentJob,
          map: mappingObject,
        });
        renderKey.value++;
      } else if (props.mappingType === 'inbound') {
        Object.assign(mappingObject, {
          inboundLabel: inboundOutboundLabelText.value,
          inboundOverwrite: true,
        });
        inboundOutboundLabelText.value = '';
        mappings.value.push(mappingObject);
      } else {
        const outboundMappingObject: IOutboundMappingItem = {
          customFieldId: selectedWorkOrderField.value.value,
          fieldName: selectedWorkOrderField.value.text,
          label: inboundOutboundLabelText.value,
          format: 'string',
          emptyValue: 'null',
        };
        inboundOutboundLabelText.value = '';
        mappings.value.push(outboundMappingObject);
      }

      selectedWorkOrderField.value = null;
      if (props.mappingType !== 'template') Store.dispatch(`${mappingNamespace}/setMappings`, mappings.value);
    };

    /**
       * Add the mapping between the job Workflow steps and WO template
       * into vuex and clear the field values
       */
    const onAddWorkflowMapping = () => {
      const mappingObject: ITemplateMappingItem = {
        workOrderFieldId: selectedWorkOrderField.value.value,
        workOrderFieldName: selectedWorkOrderField.value.text,
      };
      Object.assign(mappingObject, {
        jobWorkflowId: selectedJobWorkflow.value.value,
        jobWorkflowName: selectedJobWorkflow.value.text,
      });

      selectedWorkOrderField.value = null;
      selectedJobWorkflow.value = null;
      Store.commit('templateMapping/addWorkflowMapping', {
        templateId,
        jobId: currentJob,
        map: mappingObject,
      });
      renderKey.value++;
    };

    /**
       * Delete the selected mapping
       */
    const onDeleteMapping = (
      item: any,
    ) => {
      let updatedMappings;
      if (props.mappingType !== 'template') {
        updatedMappings = mappings.value.filter(
          (savedItem: ITemplateMappingItem | IOutboundMappingItem | IInboundMappingItem) => {
            if ('jobFieldId' in savedItem && 'jobFieldId' in item) {
              return (savedItem.workOrderFieldId !== item.workOrderFieldId && savedItem.jobFieldId !== item.jobFieldId);
            }
            if ('customFieldId' in savedItem && 'customFieldId' in item) {
              return (savedItem.customFieldId !== item.customFieldId && savedItem.label !== item.label);
            }
            if ('inboundLabel' in savedItem && 'inboundLabel' in item) {
              return (savedItem.workOrderFieldId !== item.workOrderFieldId && savedItem.inboundLabel !== item.inboundLabel);
            }
            return false;
          },
        );
        Store.dispatch(`${mappingNamespace}/setMappings`, updatedMappings);
      } else {
        const { id } = item;

        if (item.jobFieldId && id) { // mapping
          Store.commit('templateMapping/addDeletedMappings', {
            id,
            type: 'field',
          });
        } else if (item.jobWorkflowId && id) {
          Store.commit('templateMapping/addDeletedMappings', {
            id,
            type: 'workflow',
          });
        } else if (item.jobFieldId && !id) {
          // no id so this is a newly added item
          Store.commit('templateMapping/removeNewMapping', {
            templateId,
            jobId: currentJob,
            workOrderFieldId: item.workOrderFieldId,
            jobFieldId: item.jobFieldId,
          });
        } else if (item.jobWorkflowId && !id) {
          Store.commit('templateMapping/removeNewWorkflowMapping', {
            templateId,
            jobId: currentJob,
            workOrderFieldId: item.workOrderFieldId,
            jobWorkflowId: item.jobWorkflowId,
          });
        }
      }
    };

    const updateMapping = () => {
      if (props.mappingType === 'outbound') {
        Store.dispatch(`${mappingNamespace}/setMappings`, mappings.value);
      }
    };

    /**
       * Emits an event warning that user wants to navigate to the previous page
       * of the wizard and passes the current step as param
       * Clears the table and the combobox fields
       */
    const navigateBack = () => {
      selectedWorkOrderField.value = null;
      selectedJobField.value = null;
      inboundOutboundLabelText.value = '';

      emit('navigateBack', mappingBackDirection);
    };

    /**
     * Emits an event warning that user wants to navigate to the next page
     * of the wizard and passes the current step as param
     */
    const navigateNext = () => {
      if (props.mappingType === 'outbound') {
        outboundMessage.value.fields = mappings.value;
        Store.dispatch(`${mappingNamespace}/setEditedOutboundMessage`, outboundMessage.value);
      }
      emit('navigateNext', mappingBackDirection);
    };

    /**
      * disableButton is computed property that checks depending on the step certain consitions for if the next button should be disabled
      * if a value is loading it should also be disbaled, if it is mapping step 3 then it should check that some mappings have been added,
      * if it is mapping step 4 then it should check some workflowMappings are added
     */
    const disableButton = computed(() => (isLoading.value) || (props.mappingStep === 3 && mappings.value.length <= 0) || (props.mappingStep === 4 && workflowMappings.value.length <= 0));

    return {
      navigateBack,
      form,
      isLoading,
      renderKey,
      // wfRenderKey,
      loadingWorkflows,
      inboundOutboundLabelText,
      jobTemplateField,
      workOrderTemplateField,
      jobFields,
      workOrderFields,
      onChangeJobField,
      onChangeWorkOrderField,
      selectedJobField,
      selectedWorkOrderField,
      validateFields,
      onAddMapping,
      onDeleteMapping,
      mappings,
      errorMessages,
      isMappingAdded,
      navigateNext,
      emptyValueOptions,
      inboundOutboundLabelField,
      validateInboundOutbound,
      updateMapping,
      jobWorkflowStep,
      jobWorkflowSteps,
      selectedJobWorkflow,
      onChangeWorkflowField,
      workflowMappings,
      validateWorkflowSteps,
      onAddWorkflowMapping,
      loadWorkOrderFields,
      disableButton,
    };
  },
});
