


























































































































import {
  defineComponent,
  onMounted,
  computed,
  watch,
  ref,
  reactive,
  Ref,
} from '@vue/composition-api';
import { DateTime } from 'luxon';
import { getDateTimeStringFromIso } from '@/utils';

export default defineComponent({
  name: 'VDatetimePicker',
  model: {
    prop: 'datetime',
    event: 'input',
  },
  props: {
    pickerType: {
      type: String,
      default: 'datetime',
    },
    datetime: {
      type: [Date, String],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    isRange: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    label: {
      type: String,
      default: '',
    },
    menuWidth: {
      type: Number,
      default: 290,
    },
    dateFormat: {
      type: String,
      default: 'yyyy-MM-dd',
    },
    timeFormat: {
      type: String,
      default: 'HH:mm',
    },
    clearText: {
      type: String,
      default: 'CANCEL',
    },
    okText: {
      type: String,
      default: 'OK',
    },
    textFieldProps: {
      type: Object,
      default: null,
    },
    datePickerProps: {
      type: Object,
      default: null,
    },
    timePickerProps: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
    const DEFAULT_TIME_FORMAT = 'HH:mm:ss';
    const DEFAULT_DATE = null;
    const DEFAULT_TIME = null;
    const date: Ref<string | string[] | null> = ref(DEFAULT_DATE);
    const time: Ref<string | null> = ref(DEFAULT_TIME);
    const activeTab = ref(0);
    const dateTimeValue: Ref<string | string[] | null> = ref(props.datetime);
    const formattedDatetime = ref('');

    const dateTimePicker = reactive({
      menu: false as boolean,
      dialog: false as boolean,
    });

    /**
     * Set the date and time picker values if an initial value was provided in props
     * Set formattedDatetime to show the date time in the text box in dd/mm/yyyy hh:mm:ss format
     */
    const init = () => {
      if (!dateTimeValue.value) return;
      if (typeof dateTimeValue.value === 'string') {
        date.value = DateTime.fromISO(dateTimeValue.value).toFormat('yyyy-LL-dd');
        if (props.pickerType === 'datetime') time.value = DateTime.fromISO(dateTimeValue.value).toFormat('HH:mm');
        formattedDatetime.value = props.pickerType === 'datetime' ? getDateTimeStringFromIso(dateTimeValue.value) : new Date(dateTimeValue.value).toLocaleString().split(',')[0];
      }
    };

    onMounted(() => {
      init();
    });

    /**
     * Computed function to combine the provided date format and time format into a datetime format
     */
    const dateTimeFormat = computed(
      () => `${props.dateFormat} ${props.timeFormat}`,
    );

    /**
     * Compute default datetime format using the default date and default time format
     */
    const defaultDateTimeFormat = computed(
      () => `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`,
    );

    /**
     * Combine the date and time selected by the user into a datetime string.
     * Return it in ISO format to be ready to be passed back to the database
     */
    const selectedDatetime = computed(() => {
      // TODO move UI formatting to the okHandler, save in db format when range is added to backend
      if (props.isRange && date.value) {
        if (props.pickerType === 'datetime' && time.value) {
          const fromDate = new Date(date.value[0]).toLocaleDateString();
          const toDate = new Date(date.value[1]).toLocaleDateString();

          if (![fromDate, toDate].includes('Invalid Date')) {
            return `${fromDate} ~ ${toDate} ${time.value}`;
          }
        } else if (props.pickerType === 'date') {
          const fromDate = new Date(date.value[0]).toLocaleDateString();
          const toDate = new Date(date.value[1]).toLocaleDateString();

          if (![fromDate, toDate].includes('Invalid Date')) {
            return `${fromDate} ~ ${toDate}`;
          }
        }
      }

      if (props.pickerType === 'datetime' && date.value && time.value) {
        let datetimeString = `${date.value} ${time.value}`;
        if (time.value.length === 5) {
          datetimeString += ':00';
        }
        return new Date(datetimeString).toISOString();
      }
      if (props.pickerType === 'date' && date.value && typeof date.value === 'string') {
        return date.value;
      }
      return null;
    });

    const dateSelected = computed(() => !date.value);

    /**
     * Reset datetime picker values
     */
    const resetPicker = () => {
      dateTimePicker.menu = false;
      dateTimePicker.dialog = false;
    };

    /**
     * Function triggered on click of the 'OK' button in the datetime picker menu.
     * Set the formattedDatetime to display the selected value to the user in the text field.
     * Emit the input event on the component, passing on the selected datetime and
     * reset the picker values.
     */
    const okHandler = () => {
      if (props.isRange && selectedDatetime.value) {
        formattedDatetime.value = selectedDatetime.value;
      } else if (selectedDatetime.value) {
        formattedDatetime.value = props.pickerType === 'datetime' ? getDateTimeStringFromIso(selectedDatetime.value) : new Date(selectedDatetime.value).toLocaleString().split(',')[0];
      }
      emit('input', selectedDatetime.value);
      emit('selectDateValue', selectedDatetime.value);
      resetPicker();
    };

    const onClearField = () => {
      formattedDatetime.value = '';
      emit('input', '');
      emit('selectDateValue', '');
    };

    /**
     * Update the active tab
     */
    const showTimePicker = () => {
      activeTab.value = 1;
    };

    // Watch if dialog has been closed, if true clears form; validation and store
    watch(dateTimeValue, () => {
      init();
    });

    return {
      activeTab,
      dateTimeFormat,
      formattedDatetime,
      defaultDateTimeFormat,
      dateTimePicker,
      dateSelected,
      date,
      time,
      init,
      okHandler,
      resetPicker,
      showTimePicker,
      onClearField,
    };
  },
});
