















































import {
  defineComponent, ref, computed,
} from '@vue/composition-api';
import Store from '@/store';

export default defineComponent({
  emit: ['navigateNext', 'navigateBack'],

  setup(_props, { emit }) {
    const mappingName = ref(
      Store.getters['outboundMapping/getData'].mappingName as string,
    );

    const nameField = {
      label: 'Name',
      rules: {
        /**
         * Checks if value is not falsy
         * @param {string} value Value to be checked
         * @return {boolean | string} True if valid or a text if false
         */
        required: (name: string | number | undefined): boolean | string => !!name || 'This field is required',
        counter: (value: string): boolean | string => {
          if (!value) return false;
          if (value.length < 3) return 'Min 3 characters';
          return value.length <= 100 || 'Max 100 characters';
        },
      },
    };

    /**
     * Checks if message name field is valid
     * @return {boolean} True if valid
     */
    const validateMessageName = computed((): boolean => {
      const required = nameField.rules.required(mappingName.value);
      const counter = nameField.rules.counter(mappingName.value);

      return (
        typeof required === 'boolean'
          && required
          && typeof counter === 'boolean'
          && counter
      );
    });

    /**
     * On message name field input update name in store
     * @param {string} value Field value
     */
    const onInputName = (value: string) => {
      if (nameField.rules.counter(value) !== true) return;
      Store.dispatch('outboundMapping/setMessageName', value);
    };

    /**
     * Emits an event warning that user wants to navigate to the next page
     * of the wizard and passes the current step as param
     */
    const navigateNext = () => {
      emit('navigateNext', 'status');
    };

    /**
     * Emits an event warning that user wants to navigate to the previous page
     * of the wizard and passes the current step as param
     */
    const navigateBack = () => {
      emit('navigateBack', 'status');
    };

    return {
      mappingName,
      nameField,
      validateMessageName,
      onInputName,
      navigateNext,
      navigateBack,
    };
  },
});
