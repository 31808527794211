var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"template-mappings-table"},[_c('v-expansion-panels',{staticClass:"d-flex d-sm-none"},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" Search Filters ")]),_c('v-expansion-panel-content',[_c('v-text-field',{staticClass:"font-weight-regular",attrs:{"id":"mappings-table__identifier-mob","label":"Work Order Template","data-column-filter":"identifier","clearable":""}}),_c('v-text-field',{staticClass:"font-weight-regular",attrs:{"id":"mappings-table__created-by-mob","label":"Created By","clearable":"","data-column-filter":"createdBy"}}),_c('v-dialog',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onCreatedAtCancel),expression:"onCreatedAtCancel"}],attrs:{"id":"mappings-table__created-at-dialog-mob","persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"font-weight-regular",attrs:{"id":"mappings-table__created-at-mob","label":"Created On","data-column-filter":"createdAt","clearable":"","readonly":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datePicker.createdAt.dialog),callback:function ($$v) {_vm.$set(_vm.datePicker.createdAt, "dialog", $$v)},expression:"datePicker.createdAt.dialog"}},[_c('v-date-picker',{staticClass:"d-sm-none",attrs:{"scrollable":"","range":""},model:{value:(_vm.datePicker.createdAt.value),callback:function ($$v) {_vm.$set(_vm.datePicker.createdAt, "value", $$v)},expression:"datePicker.createdAt.value"}},[_c('v-spacer'),_c('v-btn',{attrs:{"id":"mappings-table__created-at-cancel-mob","text":"","color":"secondary"},on:{"click":function($event){return _vm.onCreatedAtCancel()}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"id":"mappings-table__created-at-ok-mob","text":"","color":"primary"},on:{"click":function($event){return _vm.onCreatedAtOk()}}},[_vm._v(" OK ")])],1)],1),_c('v-dialog',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onUpdatedAtCancel),expression:"onUpdatedAtCancel"}],attrs:{"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"font-weight-regular",attrs:{"label":"Last Change","data-column-filter":"updatedAt","readonly":"","clearable":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datePicker.updatedAt.dialog),callback:function ($$v) {_vm.$set(_vm.datePicker.updatedAt, "dialog", $$v)},expression:"datePicker.updatedAt.dialog"}},[_c('v-date-picker',{staticClass:"d-sm-none",attrs:{"scrollable":"","range":""},model:{value:(_vm.datePicker.updatedAt.value),callback:function ($$v) {_vm.$set(_vm.datePicker.updatedAt, "value", $$v)},expression:"datePicker.updatedAt.value"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){return _vm.onUpdatedAtCancel()}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.onUpdatedAtOk()}}},[_vm._v(" OK ")])],1)],1),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"primary"}},[_vm._v(" Search ")])],1)],1)],1)],1),_c('v-data-table',{staticClass:"\n      template-mappings-table__data-table\n      fill-height\n      pt-3\n      d-flex\n      flex-column\n      justify-space-between\n    ",attrs:{"headers":_vm.headers,"items":_vm.mappingType === 'template' ? _vm.mappingsData : _vm.getMappingData,"loading":_vm.isLoading,"fixed-header":""},scopedSlots:_vm._u([{key:"header.identifier",fn:function(ref){
var header = ref.header;
return [_c('v-text-field',{staticClass:"d-inline-block font-weight-regular",attrs:{"id":"mappings-table__identifier","data-column-filter":"identifier","clearable":"","label":header.text},on:{"click":function($event){$event.stopPropagation();}}})]}},{key:"header.createdByName",fn:function(ref){
var header = ref.header;
return [_c('v-text-field',{staticClass:"d-inline-block font-weight-regular",attrs:{"id":"mappings-table__created-by-name","data-column-filter":"createdBy","clearable":"","label":header.text},on:{"click":function($event){$event.stopPropagation();}}})]}},{key:"header.createdAt",fn:function(ref){
var header = ref.header;
return [_c('v-menu',{attrs:{"id":"mappings-table__created-at-menu","close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"d-inline-block font-weight-regular",attrs:{"id":"mappings-table__created-at-text","data-column-filter-field":"createdAt","clearable":"","readonly":"","label":header.text}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.datePicker.createdAt.menu),callback:function ($$v) {_vm.$set(_vm.datePicker.createdAt, "menu", $$v)},expression:"datePicker.createdAt.menu"}},[_c('v-date-picker',{attrs:{"scrollable":"","range":""},model:{value:(_vm.datePicker.createdAt.value),callback:function ($$v) {_vm.$set(_vm.datePicker.createdAt, "value", $$v)},expression:"datePicker.createdAt.value"}},[_c('v-spacer'),_c('v-btn',{attrs:{"id":"mappings-table__created-at-cancel-btn","text":"","color":"secondary"},on:{"click":_vm.onCreatedAtCancel}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"id":"mappings-table__created-at-ok-btn","text":"","color":"primary"},on:{"click":_vm.onCreatedAtOk}},[_vm._v(" OK ")])],1)],1)]}},{key:"header.updatedAt",fn:function(ref){
var header = ref.header;
return [_c('v-menu',{attrs:{"id":"mappings-table__updated-at-menu","close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"d-inline-block font-weight-regular",attrs:{"id":"mappings-table__updated-at-text","data-column-filter":"updatedAt","clearable":"","readonly":"","label":header.text}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.datePicker.updatedAt.menu),callback:function ($$v) {_vm.$set(_vm.datePicker.updatedAt, "menu", $$v)},expression:"datePicker.updatedAt.menu"}},[_c('v-date-picker',{attrs:{"scrollable":"","range":""},model:{value:(_vm.datePicker.updatedAt.value),callback:function ($$v) {_vm.$set(_vm.datePicker.updatedAt, "value", $$v)},expression:"datePicker.updatedAt.value"}},[_c('v-spacer'),_c('v-btn',{attrs:{"id":"mappings-table__updated-at-cancel-btn","text":"","color":"secondary"},on:{"click":_vm.onUpdatedAtCancel}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"id":"mappings-table__updated-at-ok-btn","text":"","color":"primary"},on:{"click":_vm.onUpdatedAtOk}},[_vm._v(" OK ")])],1)],1)]}},{key:"header.actions",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"d-sr-only"},[_vm._v(_vm._s(header.text))])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDateTimeStringFromIso(item.createdAt))+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDateTimeStringFromIso(item.updatedAt))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-space-around justify-lg-start"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"id":"mappings-table__btn","icon":"","disabled":_vm.isLoading},on:{"click":function($event){return _vm.directToEditPage(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(item.published ? 'mdi-file-find' : 'mdi-pencil')+" ")])],1)]}}],null,true)},[(item.published)?_c('span',[_vm._v("View")]):_c('span',[_vm._v("Edit")])])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }