










































import {
  defineComponent, computed,
} from '@vue/composition-api';
import Store from '@/store';

export default defineComponent({
  emit: ['navigateNext', 'navigateBack'],

  setup(_props, { emit }) {
    const endpoint = computed(() => Store.getters['outboundMapping/getEndpoint'] as string);

    const endpointField = {
      label: 'Endpoint',
      rules: {
        /**
         * Checks if value is not falsy
         * @param {string} value Value to be checked
         * @return {boolean | string} True if valid or a text if false
         */
        required: (name: string | number | undefined): boolean | string => !!name || 'This field is required',
      },
    };

    /**
     * Checks if message name field is valid
     * @return {boolean} True if valid
     */
    const validateEndpoint = computed((): boolean => {
      const required = endpointField.rules.required(endpoint.value);

      return (
        typeof required === 'boolean' && required
      );
    });

    /**
     * On message name field input update name in store
     * @param {string} value Field value
     */
    const onInputEndpoint = (value: string) => {
      Store.dispatch('outboundMapping/setEndpoint', value);
    };

    /**
     * Emits an event warning that user wants to navigate to the next page
     * of the wizard and passes the current step as param
     */
    const navigateNext = () => {
      emit('navigateNext', 'endpoint');
    };

    /**
     * Emits an event warning that user wants to navigate to the previous page
     * of the wizard and passes the current step as param
     */
    const navigateBack = () => {
      emit('navigateBack', 'endpoint');
    };

    return {
      endpoint,
      endpointField,
      validateEndpoint,
      onInputEndpoint,
      navigateNext,
      navigateBack,
    };
  },
});
