












































import {
  defineComponent,
  ref,
  computed,
  onMounted,
} from '@vue/composition-api';
import Store from '@/store';
import { IJobTemplate, IVComboboxItem } from '@/types';

export default defineComponent({
  emit: ['navigateNext', 'navigateBack'],

  setup(_props, { emit }) {
    const isLoading = ref(true);
    onMounted(async () => {
      await Store.dispatch('jobTemplate/loadData');
      await Store.dispatch('templateMapping/loadTemplateData');
      isLoading.value = false;
    });

    const jobTemplate = ref();

    const jobTemplateField = {
      label: 'Job Template',
      rules: {
        /**
         * Checks if value is not falsy
         * @param {string} value Value to be checked
         * @return {boolean | string} True if valid or a text if false
         */
        required: (template: string | number | undefined): boolean | string => !!template || 'This field is required',
      },
      items: (): IVComboboxItem[] => {
        const items = Store.getters['templateMapping/getTemplateJobs'] as IJobTemplate[];
        return items.map((item, index) => ({
          text: item.jobTemplateName || `Job Template ${index + 1}`,
          value: item.jobTemplateId,
          default: false,
          jobRefId: item.id,
        }));
      },
    };

    /**
     * Checks if template name field is valid
     * @return {boolean} True if valid
     */
    const validateJobTemplate = computed((): boolean => {
      const jobTemplateValue = Store.getters['templateMapping/getData'].jobTemplate;

      if (!jobTemplateValue) return false;

      const required = jobTemplateField.rules.required(jobTemplateValue);
      return typeof required === 'boolean' && required;
    });

    /**
     * On template name field input updated name in store
     * @param {string} value Field value
     */
    const onChangeJobTemplate = (
      template: IVComboboxItem | undefined,
    ) => {
      const value = template && template.value ? template.value : '';
      Store.dispatch('templateMapping/setJobTemplate', value);

      Store.commit('templateMapping/setJobTemplateRefId', template?.jobRefId);
    };

    /**
     * Emits an event warning that user wants to navigate to the next page
     * of the wizard and passes the current step as param
     */
    const navigateNext = () => {
      Store.dispatch('templateMapping/setJobTemplate', jobTemplate.value.value);
      emit('navigateNext', 'jobTemplate');
    };

    /**
     * Emits an event warning that user wants to navigate to the previous page
     * of the wizard and passes the current step as param
     */
    const navigateBack = () => {
      emit('navigateBack', 'jobTemplate');
    };

    return {
      jobTemplate,
      jobTemplateField,
      validateJobTemplate,
      isLoading,
      onChangeJobTemplate,
      navigateNext,
      navigateBack,
    };
  },
});
