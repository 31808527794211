






































































import { defineComponent, ref, computed } from '@vue/composition-api';
import Store from '@/store';
import { IInboundMappingItem } from '@/types';

export default defineComponent({
  props: {
    inboundLabel: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const dialog = ref(false);
    const form = ref();
    const maxNameLength = 100;
    const newLabel = ref('');

    const mappings = computed(() => Store.getters['inboundMapping/getMappings']);

    const inboundLabelField = {
      label: 'Inbound Label',
      counter: maxNameLength,
      rules: {
        /**
         * Checks if value is lesser or equal to 100 characters
         * @param {string} value Value to be checked
         * @return {boolean | string} True and string if lesser or equal
         */
        counter: (value: string): boolean | string => {
          if (!value) return true;
          return value.length <= maxNameLength || 'Max 100 characters';
        },

        noDuplicates: (value: string) => {
          if (props.inboundLabel === value) return true;
          if (value) {
            const itemIndex = mappings.value.findIndex((item: IInboundMappingItem) => 'inboundLabel' in item && item.inboundLabel === value);
            return itemIndex >= 0 ? 'Label has already been used' : true;
          }
          return true;
        },
      },
    };

    const setInboundLabel = () => {
      newLabel.value = props.inboundLabel;
    };

    const closeDialog = () => {
      dialog.value = false;
      form.value.reset();
    };

    const saveLabel = () => {
      const updateIndex = mappings.value.findIndex((mapping: IInboundMappingItem) => mapping.inboundLabel === props.inboundLabel);
      mappings.value[updateIndex].inboundLabel = newLabel.value;

      Store.dispatch('inboundMapping/setMappings', mappings.value);
      closeDialog();
    };

    /**
     * Checks if inbound label field is valid
     * @return { boolean } true if valid
     */
    const validateInboundLabel = computed((): boolean => {
      const noDuplicates = inboundLabelField.rules.noDuplicates(newLabel.value);
      const counter = inboundLabelField.rules.counter(newLabel.value);

      if (!newLabel.value) return false;

      return (
        typeof noDuplicates === 'boolean'
          && noDuplicates
          && typeof counter === 'boolean'
          && counter
      );
    });

    return {
      maxNameLength,
      saveLabel,
      closeDialog,
      newLabel,
      inboundLabelField,
      dialog,
      form,
      validateInboundLabel,
      setInboundLabel,
    };
  },
});
