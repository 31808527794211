














































































































































































































































































































import {
  reactive, defineComponent, computed, PropType,
} from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';
import Store from '@/store';
import { getDateTimeStringFromIso } from '@/utils';
import { IWorkorderTemplateData } from '@/types';

export default defineComponent({
  props: {
    mappingType: {
      type: String as PropType<'template' | 'outbound' | 'inbound'>,
      default: '',
    },
    mappingsData: {
      type: Array,
      default: () => [],
    },
  },
  emit: ['directToEditPage'],
  setup(props, { emit }) {
    const mappingNamespace = `${props.mappingType}Mapping`;

    const headers: DataTableHeader[] = [
      {
        text: 'Work Order Template',
        align: 'start',
        value: 'identifier',
      },
      { text: 'Created By', value: 'createdByName' },
      { text: 'Created On', value: 'createdAt' },
      { text: 'Last Change', value: 'updatedAt' },
      { text: 'Actions', value: 'actions', sortable: false },
    ];

    // Todo : update these when template mappings have actions/apis
    const isLoading = computed(() => Store.getters[`${mappingNamespace}/getLoading`]);

    const getMappingData = computed(() => (props.mappingType !== 'template'
      ? Store.getters[`${mappingNamespace}/getMappedTemplates`] : ''));

    const datePicker = reactive({
      createdAt: {
        value: [] as string[],
        menu: false,
        dialog: false,
      },
      updatedAt: {
        value: [] as string[],
        menu: false,
        dialog: false,
      },
    });

    /**
     * Closses Created At datepicker menu
     */
    const onCreatedAtCancel = () => {
      datePicker.createdAt.menu = false;
      datePicker.createdAt.dialog = false;
    };

    /**
     * Closses Created At datepicker menu
     */
    const onCreatedAtOk = () => {
      datePicker.createdAt.menu = false;
      datePicker.createdAt.dialog = false;
    };

    const onUpdatedAtCancel = () => {
      datePicker.updatedAt.menu = false;
      datePicker.updatedAt.dialog = false;
    };

    const onUpdatedAtOk = () => {
      datePicker.updatedAt.menu = false;
      datePicker.updatedAt.dialog = false;
    };

    const directToEditPage = (item: IWorkorderTemplateData) => {
      emit('directToEditPage', item);
    };

    return {
      headers,
      datePicker,
      isLoading,
      getMappingData,
      onCreatedAtCancel,
      onCreatedAtOk,
      onUpdatedAtCancel,
      onUpdatedAtOk,
      directToEditPage,
      getDateTimeStringFromIso,
    };
  },
});
