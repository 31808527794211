







































































































































































































































import {
  defineComponent,
  ref,
  computed,
  watch,
} from '@vue/composition-api';
import _ from 'lodash';
import Store from '@/store';
import {
  IFieldConditions,
  IOutboundAlert,
  ITemplateStatus,
  IWorkorderFieldType,
  IWorkorderFieldTypeStructure,
} from '@/types';
import DateTimePicker from '../TemplateCustomisation/DateTimePicker.vue';

export default defineComponent({
  emit: ['navigateNext', 'navigateBack'],
  components: {
    DateTimePicker,
  },
  setup(_props, { emit }) {
    const form = ref();
    const errorMessages = ref('');
    const selectedTrigger = ref('Status Change');
    const selectedField = ref({
      value: null,
      text: '',
      type: '',
    });
    const selectedCondition = ref({
      text: 'Equal to',
      value: '=',
    });
    const selectedValue = ref();

    const triggerOptions = ['Status Change', 'Field Change'];
    const conditionOptions = computed(() => {
      const allFieldConditions = Store.getters['outboundMapping/getFieldConditions'] as IFieldConditions;
      allFieldConditions.date = allFieldConditions?.date.filter((condition) => !condition.value.includes('xdays'));
      return allFieldConditions || '';
    });

    const checkboxOptions = [
      {
        text: 'Checked',
        value: true,
      },
      {
        text: 'Not Checked',
        value: false,
      },
    ];

    const resetSelectedTrigger = () => {
      selectedTrigger.value = 'Status Change';
    };

    const headers = [
      { text: 'When', align: 'start', value: 'action' },
      { text: 'What', value: 'customFieldName' },
      { text: 'Condition', value: 'criteriaText' },
      { text: 'Value', value: 'value' },
      { text: 'Actions', value: 'actions', sortable: false },
    ];

    const alerts = computed(
      (): (IOutboundAlert)[] => Store.getters['outboundMapping/getAlerts'],
    );

    const workOrderFields = computed(() => {
      const allWorkorderFields = Store.getters['templateMapping/getWorkOrderFields'];

      // @TODO remove fields when fellipe updates alarm criteria api
      const ignoreFields = ['table', 'joblist', 'richtext', 'notes'];
      return allWorkorderFields.filter((field: IWorkorderFieldType) => !ignoreFields.includes(field.type));
    });

    // return all status's for template
    const listOfStatuses = computed(() => Store.getters['templateStatus/getData']);

    // return array of names to display in dropdown
    const statusOptions = computed(() => listOfStatuses.value.map((item: ITemplateStatus) => item.name));

    const rules = {
      /**
       * Checks if value is not falsy
       * Don't show error message when 'null' as that's the field value when it's
       * cleared after adding a new mapping
       * @param {string} value Value to be checked
       * @return {boolean | string} True if valid or a text if false
       */
      required: (field: string | number | boolean | undefined): boolean | string => {
        if (field == null) return true;
        if (!field && field !== false) return 'This field is required';
        return true;
      },
      unique: (name: string) => {
        const statusAlarms = alerts.value.filter((alert) => alert.action === 'Status Change');
        const alreadyAdded = statusAlarms.find((alarm) => alarm.value === name);
        if (alreadyAdded) return 'Status cannot be used twice';
        return true;
      },
    };

    /**
     * Check if all fields pass validation i.e they have values
     */
    const validateFields = computed((): boolean => {
      if (!selectedTrigger.value
        || (selectedTrigger.value === 'Status Change' && !selectedValue.value)
        || (selectedTrigger.value === 'Field Change' && (!selectedField.value.value || !selectedCondition.value.value))
        || (selectedTrigger.value === 'Field Change' && selectedField.value.value && selectedField.value.type !== 'file' && selectedCondition.value.value !== 'not_empty' && !selectedValue.value && selectedValue.value !== false
        )
      ) return false;

      const statusUnique = rules.unique(selectedValue.value);

      let required = rules.required(selectedTrigger.value);
      if (selectedTrigger.value === 'Field Change' && selectedField.value.value) {
        required = required && rules.required(selectedField.value.value)
        && rules.required(selectedCondition.value.value);
        if (selectedField.value.type !== 'file') {
          required = required && rules.required(selectedValue.value);
        }
      }

      return (
        typeof required === 'boolean'
          && required
        && typeof statusUnique === 'boolean'
         && statusUnique
      );
    });

    const getFieldType = (fieldId: number) => {
      const fieldData = workOrderFields.value.find((field: IWorkorderFieldType) => field.value === fieldId);
      return fieldData?.type || '';
    };

    const getSelectOptions = (fieldId: number) => {
      const fieldData = workOrderFields.value.find((field: IWorkorderFieldType) => field.value === fieldId);
      return fieldData.structure;
    };

    const getSelectItemDescription = (fieldData: IOutboundAlert) => {
      const structure = getSelectOptions(fieldData.customFieldId as number);
      if (structure) {
        const selectItem = structure.find((item: IWorkorderFieldTypeStructure) => item.code === fieldData.value);
        return selectItem.description;
      }
      return '';
    };

    /**
     * Add the alarm the selected fields into vuex and clear the field values
     */
    const onAddAlarm = () => {
      const alertDetails = {};

      if (selectedTrigger.value === 'Field Change' && selectedField.value.value) {
        Object.assign(alertDetails, {
          action: 'Field Change',
          operator: 'OR',
          customFieldId: selectedField.value.value,
          customFieldName: selectedField.value.text,
          criteria: selectedCondition.value.value,
          criteriaText: selectedCondition.value.text,
          value: selectedValue.value,
          id: _.uniqueId(),
        });
      } else {
        // Alarm set on status change
        const statusId = listOfStatuses.value.find((status: ITemplateStatus) => status.name === selectedValue.value).id;
        Object.assign(alertDetails, {
          action: 'Status Change',
          outboundMessageId: _.uniqueId(),
          name: selectedValue.value,
          customFieldName: 'Status',
          criteriaText: 'Changes to',
          value: selectedValue.value,
          id: _.uniqueId(),
          statusId,
        });
      }

      alerts.value.push(alertDetails);
      selectedValue.value = null;
      selectedCondition.value = {
        text: '',
        value: '',
      };
      Store.dispatch('outboundMapping/setAlerts', alerts.value);
    };

    /**
     * Delete the selected alarm
     */
    const onDeleteAlarm = (
      item: IOutboundAlert,
    ) => {
      const updatedAlarms = alerts.value.filter(
        (savedItem) => (
          savedItem.id !== item.id
        ),
      );
      Store.dispatch('outboundMapping/setAlerts', updatedAlarms);

      if (item.action === 'Field Change') {
        Store.dispatch('outboundMapping/addAlarmToFieldDeleteArray', item.id);
      } else {
        Store.dispatch('outboundMapping/addAlarmToStatusDeleteArray', item.value);
      }
    };

    /**
     * Check that at least one mapping is there before allowing the user to proceed
     */
    const isAlertAdded = computed(() => alerts.value && alerts.value.length > 0);

    /**
     * When the selected trigger changes, reset the selected value
     */
    watch(selectedTrigger, () => {
      selectedValue.value = null;
      selectedCondition.value = {
        text: '',
        value: '',
      };
    });

    /**
     * Emits an event warning that user wants to navigate to the previous page
     * of the wizard and passes the current step as param
     * Clears the table and the combobox fields
     */
    const navigateBack = () => {
      emit('navigateBack', 'alarms');
    };

    /**
     * Emits an event warning that user wants to navigate to the next page
     * of the wizard and passes the current step as param
     */
    const navigateNext = () => {
      emit('navigateNext', 'alarms');
    };

    const loadFieldConditions = () => Store.dispatch('outboundMapping/loadFieldConditions');

    loadFieldConditions();

    return {
      navigateBack,
      form,
      workOrderFields,
      validateFields,
      onAddAlarm,
      onDeleteAlarm,
      alerts,
      errorMessages,
      isAlertAdded,
      navigateNext,
      triggerOptions,
      conditionOptions,
      checkboxOptions,
      headers,
      rules,
      selectedTrigger,
      selectedField,
      selectedCondition,
      selectedValue,
      statusOptions,
      resetSelectedTrigger,
      getFieldType,
      getSelectOptions,
      getSelectItemDescription,
    };
  },
});
